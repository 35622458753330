.sc-loader {
    margin: 40px 0;
    height: 120px;
    overflow: hidden;
    position: relative;
}

.sc-loader::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 50%;
    height: 7em;
    width: 7em;
    border-top: 1.1em solid rgba(40, 167, 67, 0.2);
    border-right: 1.1em solid rgba(40, 167, 67, 0.2);
    border-bottom: 1.1em solid rgba(40, 167, 67, 0.2);
    border-left: 1.1em solid #28a743;
    transform: translateZ(0);
    animation: load8 1.1s infinite linear;
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
