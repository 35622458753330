@import url(https://fonts.googleapis.com/css?family=Open+Sans);
.sc-box-layout .isButton {
    position: relative;
}

.sc-box-layout .isButton .sc-box-layout-title {
    margin-top: 24px;
}
.sc-box-layout .isButton .sc-box-layout-title.isShadowLineHeader {
    margin-top: 48px;
}
.sc-box-layout .isButton > button {
    position: absolute;
}

.sc-box-layout-title {
    text-align: center;
    border-bottom: none;
    margin-bottom: 0;
}
.sc-box-layout-title.isUnderlinedHeader {
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 16px;
}
.sc-box-layout-title.isShadowLineHeader {
    position: relative;
}
.sc-box-layout-title.isShadowLineHeader::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 18px;
    margin: 0 auto;
    height: 3px;
    width: 80%;
    background: linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(130,216,80,1) 25%, rgba(130,216,80,1) 75%, rgba(0,212,255,0) 100%);
}

.sc-box-layout-title.isShadowLineHeader h2 {
    position: relative;
    z-index: 2;
}
.sc-box-layout-title.isShadowLineHeader h2::before {
    content: '';
    position: absolute;
    top: -4px;
    left: -42px;
    height: 30px;
    width: calc(100% + 60px);
    background: #fff;
    z-index: -1;
}


.sc-box-layout-title i {
    position: relative;
    display: inline-block;
    margin-right: 8px;
    color: #8ec154;
    font-size: 20px;
    z-index: 3;
}
.sc-box-layout-title h2 {
    display: inline-block;
    margin: 8px 0;
    padding: 0;
    font-size: 20px;
}
.sc-box-layout-title.isUnderlinedHeader h2 {
    padding-bottom: 8px;
}

.sc-box-layout-description {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.sc-box-layout-description h2 {
    font-size: 16px;
    font-weight: 600;
}

.sc-box-layout-description h2 span {
    color: #8ec154;
}
.sc-box-layout-description h2 span span {
    color: #212529;
}

.sc-box-layout-description p {
    font-size: 14px;
    line-height: 28px;
    color: #212529;
}

.sc-box-layout-description button {
    height: 44px;
    margin-left: 20px;
    white-space: nowrap;
}

.sc-box-layout-description + .sc-progress-bar {
    margin: 18px;
}

button.sc-button {
    position: relative;
    padding: 8px 16px;
    outline: none !important;
    border: 2px solid #8ec154;
    border-radius: 4px;
    transition: 0.6s;
    color: #fff;
    background: #8ec154;
}
button.sc-button.isLoading:hover,
button.sc-button.isDisabled {
    transition: color 0s;
    pointer-events: none;
    border: 2px solid #b4e896;
    background: #b4e896;
    cursor: auto;
}
.sc-button:hover {
    color: #8ec154;
    background: #fff;
    transition: 0.6s;
}
.sc-button .sc-loader {
    position: absolute;
    left: 0;
    right: 0;
    width: 20px;
    height: 20px;
    margin: 1px auto;
}
.sc-button .sc-loader::after {
    width: 20px;
    height: 20px;
    border-top: 0.2em solid #fff;
    border-right: 0.2em solid #fff;
    border-bottom: 0.2em solid #fff;
    border-left: 0.2em solid #28a743;
}
.sc-button .sc-loader + span {
    color: #b4e896;
}
.sc-button.isDisabled:hover {
    color: #fff;
    background: #b4e896;
}

.sc-button.isWhite {
    color: #8ec154;
    background: #fff;
}
.sc-button.isWhite:hover {
    color: #fff;
    background: #8ec154;
}
.sc-button.isDark {
    padding: 10px 16px;
    border: none;
    background: #6c757d;
}
.sc-button.isDark:hover {
    color: #fff;
    background: #5a6268;
}

.sc-button .extra-text {
    position: absolute;
    top: -15px;
    left: 0;
    font-size: 10px;
    line-height: 13px;
    word-break: break-word;
    text-align: left;
    white-space: pre;
}

button.sc-button-red {
    color: #fff;
    background: #dc3545;
    border: 2px solid #dc3545;
    transition: 0.6s;
}
button.sc-button-red:hover {
    color: #dc3545;
    background: #fff;
    border: 2px solid #dc3545;
}

button.sc-button-naked {
    color: #8ec154;
    padding: 4px 10px;
    border: none;
    background: none;
    transition: 0.6s;
}
button.sc-button-naked.isDisabled {
    background: none;
    border: none;
}
button.sc-button-naked.isDisabled:hover {
    color: #8ec154;
    background: none;
}
button.sc-button-naked:hover {
    color: #fff;
    background: #8ec154;
    transition: 0.6s;
}

.sc-card {
    display: flex;
    justify-content: space-around;
    min-height: 182px;
    margin-bottom: 24px;
    padding-top: 56px;
    background: #fff;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    transition: 0.6s;
}
.sc-card:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    text-decoration: none;
    transition: 0.6s;
}

.sc-card.isSimpleView {
    display: block;
    width: 200px;
    min-width: 200px;
    margin-right: 20px;
    padding-top: 35px;
}
.sc-card.isSimpleView:hover {
    border-color: #dee2e6;
}

.sc-card > * {
    width: calc(100% / 6);
}

.sc-card button {
    display: block;
    margin: 0 auto;
}

.sc-card.isSimpleView > * {
    max-width: 100%;
    width: 100%;
}

.sc-card.isSimpleView > div:first-child {
    width: 80px;
    margin: 0 auto;
}

.sc-card-icon {
    position: relative;
}
.sc-card-icon .sc-card-icon-background {
    position: relative;
    top: -8px;
    width: 80px;
    height: 80px;
    background: #8ec154;
    margin: 0 auto;
    border-radius: 50%;
    text-align: center;
}
.sc-card-icon i {
    color: #fff;
    position: absolute;
    display: block;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 30px;
}

.sc-card-values > * {
    text-align: center;
    font-size: 16px;
    padding: 0 10px;
}
.sc-card-values h6:first-child {
    font-weight: 600;
}

.sc-card-values p.isBold {
    font-weight: 600;
    margin: 10px 0 5px;
}

.sc-card.isSimpleView .sc-card-values:nth-child(2) h6 {
    margin: 18px 0 10px;
}

.sc-card button {
    height: 44px; 
}

.sc-card.isSimpleView button {
    width: auto;
    display: block;
    margin: 25px auto;
}

@media (max-width: 767px) {
    .sc-card {
        display: block;
    }
    .sc-card > * {
        max-width: 100%;
        width: 100%;
    }
    .sc-card > div:first-child {
        width: 80px;
        margin: 0 auto;
    }

    .sc-card button {
        width: auto;
        display: block;
        margin: 20px auto 30px;
    }
}

.sc-footer-top {
    padding: 32px 48px 32px 32px;
    background: #8ec154;
}

.sc-footer p,
.sc-footer a {
    display: inline-block;
    color: #fff;
    white-space: pre;
    margin: 0;
}

.sc-footer a {
    display: inline-block;
    border-bottom: 1px solid #fff;
    white-space: pre;
    font-weight: 600;
    color: #fff;
    transition: 0.5s;
}
.sc-footer a:hover {
    transition: 0.5s;
    border-bottom: 1px solid #000;
}

.sc-footer-bottom {
    padding: 32px 48px 32px 32px;
}
.sc-footer-bottom p,
.sc-footer-bottom a {
    color: #000;
    font-weight: 600;
}
.sc-footer-bottom a {
    border-bottom: 1px solid #000;
}
.sc-footer-bottom a:hover {
    border-bottom: 1px solid #8ec154;
    color: #8ec154;
    transition: 0.5s;
}

.sc-flex-grid-wrapper {
    overflow: hidden;
}

.sc-flex-grid {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
}

.sc-grid-empty-box {
    width: 200px;
    height: 0;
}

.sc-header {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 105px;
    padding: 32px 48px 32px 32px;
    background: #fff;
    z-index: 100;
}

.sc-header-logo {
    width: 150px;
    height: auto;
}

.sc-header-menu-icon {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.sc-header > ul {
    float: right;
    display: flex;
    list-style: none;
}

.sc-header-current-tab {
    margin: 9px 12px;
    font-weight: 600;
    border-bottom: 2px solid #8ec154;
}
.sc-header-tab {
    margin: 9px 12px;
    font-weight: 600;
    transition: 0.5s;
}
.sc-header-tab {
    display: block;
}
.sc-header-tab::after {
    content: '';
    display: block;
    height: 2px;
    opacity: 0;
    background: #8ec154;
    transition: 0.5s;
}
.sc-header-tab:hover::after {
    transition: 0.5s;
    opacity: 1;
}

.sc-header-email {
    margin: 9px 0 0 20px;
    cursor: pointer;
}
.sc-header-email img {
    width: 16px;
    height: 16px;
    margin-right: 6px;
}

.sc-header-menu-icon {
    display: none;
}
@media all and (max-width: 991px) {
    .sc-header > ul {
        display: block;
        position: fixed;
        top: 105px;
        left: 0;
        width: 100vw;
        max-height: 0;
        overflow: hidden;
        padding: 0;
        list-style: disc;
        background: #fff;
        transition: 0.5s;
    }
    .sc-header > ul p,
    .sc-header > ul a {
        margin: 0;
    }
    .sc-header > ul.isMenuOpen {
        max-height: 180px;
        padding: 0 0 20px 51px;
        transition: 0.5s;
    }
    .sc-header > ul li {
        margin-bottom: 12px;
    }

    .sc-header-menu-icon {
        display: block;
        float: right;
        margin-top: 12px;
    }

    .sc-header-current-tab {
        display: inline;
    }
    .sc-header-tab {
        display: inline-block;
    }
}

.announcement{
    color: red;
    border: 1px solid black;
    position: absolute;
    width:40%;
}

.anncouncement-container{
    text-align: center;
    margin-left: 20%;
    display: inline-block;
}




@media only screen and (max-width: 1750px) 
{
.announcement{
    color: red;
    border: 1px solid black;
    position: absolute;
    width:50%;
}

.anncouncement-container{
    text-align: center;
    margin-left: 10%;
    display: inline-block;


}
};


.sc-input label {
    color: #212529;
}

.sc-input input {
    width: 100%;
    padding: 6px 12px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    color: #495057;
}

.sc-input input + label {
    margin-top: 6px;
    font-size: 12px;
    color: #6c757d;
}
.sc-progress-bar  {
    position: relative;
    height: 14px;
    background: #f4f8f9;
    border-radius: 7px;
}
.sc-progress-bar div {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    height: 14px;
    background: #8ec154;
    border-radius: 7px;
}
.sc-loader {
    margin: 40px 0;
    height: 120px;
    overflow: hidden;
    position: relative;
}

.sc-loader::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 50%;
    height: 7em;
    width: 7em;
    border-top: 1.1em solid rgba(40, 167, 67, 0.2);
    border-right: 1.1em solid rgba(40, 167, 67, 0.2);
    border-bottom: 1.1em solid rgba(40, 167, 67, 0.2);
    border-left: 1.1em solid #28a743;
    transform: translateZ(0);
    animation: load8 1.1s infinite linear;
}

@keyframes load8 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.sc-table-wrapper.hasMaxHeight {
    overflow: auto;
}

.sc-table-wrapper > button {
    float: right;
}

.sc-table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    color: #212121;
}

.sc-table-wrapper tr.hasHiddenValue.isExpanded,
.sc-table .main-tr:hover td {
    background: #ececec;
}

.sc-table-display-data,
.sc-table th {
    padding: 12px;
}

.sc-table td {
    position: relative;
    word-break: break-all;
}

.sc-table td i {
    color: #8ec154;
    margin-right: 8px;
}

.sc-table td.custom-td {
    padding: 2px 6px;
}
.sc-table td.custom-td button {
    padding: 4px 10px;
}
.sc-table td.custom-td button:not(:first-child) {
    margin-left: 4px;
}
.sc-table td.custom-td > span {
    font-size: 12px;
    opacity: 0.7;
}

.sc-table-wrapper tr.hasHiddenValue:hover {
    cursor: pointer;
}
.sc-table .main-tr.isCreationState:hover td {
    cursor: auto;
}
.sc-table-wrapper tr.hasHiddenValue.isExpanded + tr,
.sc-table-wrapper tr.hasHiddenValue.isExpanded + tr + .sc-table-second-hidden-tr {
    background: #f4f8f9;
}
.sc-table-wrapper tr.isExpanded + tr .sc-table-hidden,
.sc-table-wrapper tr.isExpanded + .sc-table-second-hidden-tr .sc-table-second-hidden,
.sc-table-wrapper tr.isExpanded + tr + .sc-table-second-hidden-tr .sc-table-second-hidden {
    max-height: 400px;
    transition: max-height 0.4s;
}

.sc-table-second-hidden-tr td:not(:first-child) {
    display: none;
}

.sc-table-hidden .sc-table-tooltip {
    display: none;
    position: absolute;
    top: 10px;
    max-height: 160px;
    overflow: auto;
    padding: 4px 8px;
    border: 1px solid #8ec154;
    border-radius: 4px;
    background: #fff;
    z-index: 10;
    word-break: initial;
}
.sc-table-hidden:hover {
    overflow: visible;
}
.sc-table-hidden:hover .sc-table-tooltip{
    display: block;
}

.sc-table-hidden,
.sc-table-second-hidden {
    max-height: 0;
    overflow: hidden;
    word-break: break-all;
    transition: max-height 0.4s;
}
.sc-table-hidden > *,
.sc-table-second-hidden > * {
    margin: 12px;
}
.sc-table-hidden button {
    margin-right: 0;
}

.sc-table-hidden p,
.sc-table-second-hidden p {
    padding: 0;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
}

.sc-table-hidden-tbody {
    display: grid;
    max-height: 34px;
    overflow: hidden;
}
.sc-table-hidden-tbody.isExpanded {
    display: contents;
}

.sc-table-hidden-tbody .custom-td button {
    display: none;
}

.sc-table-empty-list {
    background: #f4f8f9;
    border-radius: 2px;
    text-align: center;
    padding: 12px;
    margin: 9px 0;
}
.sc-table-empty-list i {
    color: #8ec154;
    margin-right: 8px;
}
.sc-table-empty-list h2 {
    display: inline-block;
    font-size: 16px;
    margin-bottom: 0;
}

.sc-textarea label {
    color: #212529;
}

.sc-textarea textarea {
    width: 100%;
    padding: 6px 12px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    color: #495057;
}

.sc-textarea textarea + label {
    font-size: 12px;
    color: #6c757d;
}
.sc-modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #000;
    z-index: 1040;
    opacity: 0.5;
}

.sc-modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid #dee2e6;
}
.sc-modal-header h5 {
    margin: 3px 0;
    color: #212529;
}
.sc-modal-header button {
    font-weight: 400;
}

.sc-modal-body-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 90vh;
    overflow: auto;
    width: 500px;
    margin: 0;
    z-index: 1050;
    background: #fff;;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 5px;
}

.sc-modal-layout-children {
    padding: 16px;
}

.sc-modal-footer {
    text-align: right;
}
.sc-modal-footer::before {
    position: relative;
    content: '';
    left: -16px;
    display: block;
    width: calc(100% + 32px);
    margin: 20px 0 16px;
    border-top: 1px solid #dee2e6;
}

.sc-modal-footer button:first-child {
    padding: 7px 11px;
    margin-right: 8px;
}

button.sc-button {
    position: relative;
    padding: 8px 16px;
    outline: none !important;
    border: 2px solid #8ec154;
    border-radius: 4px;
    transition: 0.6s;
    color: #fff;
    background: #8ec154;
}
button.sc-button.isLoading:hover,
button.sc-button.isDisabled {
    transition: color 0s;
    pointer-events: none;
    border: 2px solid #b4e896;
    background: #b4e896;
    cursor: auto;
}
.sc-button:hover {
    color: #8ec154;
    background: #fff;
    transition: 0.6s;
}
.sc-button .sc-loader {
    position: absolute;
    left: 0;
    right: 0;
    width: 20px;
    height: 20px;
    margin: 1px auto;
}
.sc-button .sc-loader::after {
    width: 20px;
    height: 20px;
    border-top: 0.2em solid #fff;
    border-right: 0.2em solid #fff;
    border-bottom: 0.2em solid #fff;
    border-left: 0.2em solid #28a743;
}
.sc-button .sc-loader + span {
    color: #b4e896;
}
.sc-button.isDisabled:hover {
    color: #fff;
    background: #b4e896;
}

.sc-button.isWhite {
    color: #8ec154;
    background: #fff;
}
.sc-button.isWhite.isDisabled {
    color: #b4e896;
    background: #fff;
}
.sc-button.isWhite:hover {
    color: #fff;
    background: #8ec154;
}
.sc-button.isDark {
    padding: 10px 16px;
    border: none;
    background: #6c757d;
}
.sc-button.isDark:hover {
    color: #fff;
    background: #5a6268;
}
.sc-button.isDanger {
    padding: 8px 14px;
    background: #dc3545;
    border: 2px solid #dc3545;
}
.sc-button.isDanger:hover {
    color: #dc3545;
    background: #fff;
}

button.sc-button-red {
    color: #fff;
    background: #dc3545;
    border: 2px solid #dc3545;
    transition: 0.6s;
}
button.sc-button-red:hover {
    color: #dc3545;
    background: #fff;
    border: 2px solid #dc3545;
}

button.sc-button-naked {
    color: #8ec154;
    padding: 4px 10px;
    border: none;
    background: none;
    transition: 0.6s;
}
button.sc-button-naked.isDisabled {
    background: none;
    border: none;
}
button.sc-button-naked.isDisabled:hover {
    color: #8ec154;
    background: none;
}
button.sc-button-naked:hover {
    color: #fff;
    background: #8ec154;
    transition: 0.6s;
}

button.btn-action {
    background: #8ec154;
    border-color: #8ec154;
}

.sc-button i {
    margin-right: 5px;
}

.sc-button + .sc-reset-button {
    display: inline-block;
    padding: 0 7px 0 7px;
    cursor: pointer;
}
.sc-button + .sc-reset-button i {
    color: #dc3545;
    font-size: 10px;
}

.sc-date-range-picker {
  display: inline-block;
  position: relative;
}
.sc-date-range-picker.isOpen .rdrDateRangePickerWrapper {
  display: inline-block;
  position: absolute;
  top: 30px;
  left: 0;
  border: 1px solid #8ec154;
  border-radius: 4px;
  z-index: 100;
}
.sc-date-range-picker .rdrDateRangePickerWrapper {
  display: none;
  width: 560px;
  background: #fff;
}
.sc-date-range-picker .rdrDateRangePickerWrapper > div {
  float: left;
}

.sc-modal-layout {
    position: relative;
}

.sc-modal-tabs div {
    display: inline-block;
    margin-right: 20px;
    padding-right: 20px;
    border-right: 1px solid #6c757d;
    cursor: pointer;
}
.sc-modal-tabs .isActive{
    font-weight: 600;
}

.sc-modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #000;
    z-index: 1040;
    opacity: 0.5;
}

.sc-modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid #dee2e6;
}
.sc-modal-header h5 {
    margin: 3px 0;
    color: #212529;
}
.sc-modal-header button {
    font-weight: 400;
}

.sc-modal-body-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 90vh;
    overflow: auto;
    width: 500px;
    margin: 0;
    z-index: 1050;
    background: #fff;;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 5px;
}

.sc-modal-layout-children {
    padding: 16px;
}

.sc-modal-footer {
    text-align: right;
}
.sc-modal-footer::before {
    position: relative;
    content: '';
    left: -16px;
    display: block;
    width: calc(100% + 32px);
    margin: 20px 0 16px;
    border-top: 1px solid #dee2e6;
}

.sc-modal-footer button:first-child {
    padding: 7px 11px;
    margin-right: 8px;
}

.sc-input-wrapper {
  width: 100%;
}
.sc-input-wrapper.MuiFormControl-root {
  margin-bottom: 20px;
}
.sc-input-wrapper .MuiOutlinedInput-input {
  padding: 9.5px 14px;
}
.sc-input-wrapper label,
.sc-input-wrapper label.Mui-focused {
  color: #757575;
}
.sc-input-wrapper legend {
  max-width: 0;
}
.sc-input-wrapper .MuiInputLabel-outlined.MuiInputLabel-animated {
  transform: none;
}
.sc-input-wrapper label + .MuiInputBase-formControl {
  margin-top: 30px;
}

.sc-input.isDisabled input {
  opacity: 0.5;
}

.sc-input.Mui-focused fieldset,
.sc-input.Mui-focused:hover fieldset,
.sc-input .Mui-focused fieldset.MuiOutlinedInput-notchedOutline,
.sc-input .Mui-focused:hover fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #28a745;
}
.sc-input .Mui-focused.Mui-error fieldset.MuiOutlinedInput-notchedOutline,
.sc-input
  .Mui-focused.Mui-error:hover
  fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #f44336;
}

.sc-radio-group.MuiFormGroup-root {
  flex-direction: row;
}

.sc-radio-group.MuiFormGroup-root .Mui-checked * {
  color: #8ec154;
}

.sc-radio-group .MuiButtonBase-root:hover,
.sc-radio-group .Mui-checked.MuiButtonBase-root:hover {
  background: rgba(139, 195, 74, 0.04);
}

.sc-radio-wrapper .MuiFormLabel-root,
.sc-radio-wrapper .MuiFormLabel-root.Mui-focused {
  color: #757575;
}

.sc-checkboxes-in-search {
  position: absolute;
  right: 26px;
  margin-top: -10px;
}

.sc-chosen-in-search {
  margin-top: 10px;
  color: #999;
}
.sc-select-wrapper {
  width: 100%;
}
.sc-select-wrapper.MuiFormControl-root {
  margin-bottom: 20px;
}
.sc-select-wrapper .MuiInputLabel-outlined.MuiInputLabel-animated {
  transform: none;
}
.sc-select-wrapper .MuiOutlinedInput-input {
  padding: 9.5px 14px;
}
.sc-select-wrapper label,
.sc-select-wrapper label.Mui-focused {
  color: #757575;
}
.sc-select-wrapper.isError label {
  color: #f44336;
}
.sc-select-wrapper label + .MuiInputBase-formControl {
  margin-top: 26px;
}
.sc-select-wrapper legend {
  max-width: 0;
}

.sc-multiple-select {
  min-height: 38px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
}
.sc-multiple-select:hover {
  border: 1px solid #212121;
}

.sc-multiple-select.Mui-error,
.sc-multiple-select.Mui-error:hover {
  border: 1px solid #f44336;
}
.sc-multiple-select.Mui-focused,
.sc-multiple-select.Mui-focused:hover {
  border: 2px solid #28a745;
}
.sc-multiple-select.Mui-focused > div {
  min-height: 30px;
  padding: 2px 40px 2px 2px;
}
.sc-multiple-select.Mui-focused > svg {
  right: 6px;
}
.sc-select.Mui-focused fieldset.MuiOutlinedInput-notchedOutline,
.sc-select.Mui-focused:hover fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #28a745;
}
.sc-multiple-select.Mui-focused.Mui-error,
.sc-multiple-select.Mui-focused.Mui-error:hover,
.sc-select.Mui-focused.Mui-error fieldset.MuiOutlinedInput-notchedOutline,
.sc-select.Mui-focused.Mui-error:hover
  fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #f44336;
}

.sc-multiple-select > div {
  padding: 3px 41px 3px 3px;
}

.sc-multiple-select .MuiSelect-selectMenu {
  overflow: unset;
  white-space: normal;
}

.sc-multiple-select .MuiChip-root {
  height: 26px;
  margin: 2px;
}

.sc-multiple-select.MuiInput-underline::before,
.sc-multiple-select.MuiInput-underline::after {
  border: none !important;
}

.sc-switch-wrapper {
  width: 100%;
  margin-bottom: 20px;
}
.sc-switch.MuiSwitch-root {
  width: 46px;
  height: 26.5px;
  margin-top: 26px;
  padding: 0;
}
.sc-switch .MuiSwitch-track {
  border-radius: 24px;
}
.sc-switch .MuiSwitch-switchBase {
  top: -6px;
  left: -6px;
}
.sc-switch .Mui-checked + span.MuiSwitch-track {
  opacity: 1;
  background-color: #8ec154;
}
.sc-switch .Mui-checked span.MuiSwitch-thumb {
  color: #fff;
}
.sc-switch .Mui-checked span.MuiTouchRipple-root > span {
  color: #8ec154;
}

label.sc-switch-label,
label.sc-switch-label.Mui-focused {
  color: #757575;
}
.sc-switch-label.MuiInputLabel-outlined.MuiInputLabel-animated {
  transform: none;
}

.sc-tab {
  box-shadow: none !important;
}
.sc-tab .MuiTabs-root button {
  background: #fff;
  opacity: 1;
  text-transform: capitalize;
  color: #007bff;
  font-family: 'Open Sans', sans-serif;
}
.sc-tab button.Mui-selected {
  color: #495057;
  border: 1px solid #dee2e6;
  border-bottom: 1px solid #fff;
  cursor: auto;
  pointer-events: none;
  outline: none;
}
.sc-tab button.Mui-selected .MuiTab-wrapper{
  color: #495057;
}

.sc-tab .MuiTab-wrapper {
  color: #8ec154;
}

.sc-tab .MuiTabs-indicator {
  height: 1px;
  bottom: 0;
  background: #fff;
}

.sc-tab.MuiPaper-root {
  background-color: #fff;
}

.sc-tab .MuiTabs-flexContainer {
  background: #fff;
  border-bottom: 1px solid #dee2e6;
}

.sc-tab-view {
  display: none;
}

.sc-tab-view.isShowed {
  display: block;
}

.sc-tab-view .MuiPaper-root{
  box-shadow: none !important;
}
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #efefef;
    padding-top: 105px;
}

a {
    color: #212529;
}
a:hover {
    text-decoration: none;
    color: #212529;
}

.sc-main-layout {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 106px);
}

.sc-main-layout-toolbar {
    height: 60px;
    padding: 0 37px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.sc-main-layout-toolbar.empty {
    height: 30px;
}

.sc-main-layout-body {
    flex: 1 1;
    padding: 0 30px 30px 30px;
}

.sc-box {
    padding: 24px;
    background: #fff;
    border: 1px solid #dee2e6;
    border-radius: 6px;
}
.sc-box h1,
.sc-box h2,
.sc-box h3,
.sc-box h4,
.sc-box h5,
.sc-box h6,
.sc-box p {
    font-family: 'Open Sans', sans-serif;
}

.sc-indent-top {
    margin: 0 7px;
    border-bottom: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.sc-indent-middle {
    margin: 0 7px;
    border-top: none;
    border-bottom: none;
    border-radius: 0px;
}

.sc-indent-bottom {
    margin: 0 7px;
    border-top: none;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.sc-box-overflow {
    position: relative;
    left: -8px;
    width: calc(100% + 16px);
}

.sc-no-padding {
    padding: 0;
}

.sc-loader {
    margin: 40px 0;
    height: 120px;
    overflow: hidden;
    position: relative;
}

.sc-loader::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 50%;
    height: 7em;
    width: 7em;
    border-top: 1.1em solid rgba(40, 167, 67, 0.2);
    border-right: 1.1em solid rgba(40, 167, 67, 0.2);
    border-bottom: 1.1em solid rgba(40, 167, 67, 0.2);
    border-left: 1.1em solid #28a743;
    transform: translateZ(0);
    animation: rotate360 1.1s infinite linear;
}

.sc-no-data-message {
    width: 100%;
    margin: 20px 20px 40px;
    font-size: 1.2em;
    text-align: center;
}

@keyframes rotate360 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

