.sc-input-wrapper {
  width: 100%;
}
.sc-input-wrapper.MuiFormControl-root {
  margin-bottom: 20px;
}
.sc-input-wrapper .MuiOutlinedInput-input {
  padding: 9.5px 14px;
}
.sc-input-wrapper label,
.sc-input-wrapper label.Mui-focused {
  color: #757575;
}
.sc-input-wrapper legend {
  max-width: 0;
}
.sc-input-wrapper .MuiInputLabel-outlined.MuiInputLabel-animated {
  transform: none;
}
.sc-input-wrapper label + .MuiInputBase-formControl {
  margin-top: 30px;
}

.sc-input.isDisabled input {
  opacity: 0.5;
}

.sc-input.Mui-focused fieldset,
.sc-input.Mui-focused:hover fieldset,
.sc-input .Mui-focused fieldset.MuiOutlinedInput-notchedOutline,
.sc-input .Mui-focused:hover fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #28a745;
}
.sc-input .Mui-focused.Mui-error fieldset.MuiOutlinedInput-notchedOutline,
.sc-input
  .Mui-focused.Mui-error:hover
  fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #f44336;
}
