.sc-modal-layout {
    position: relative;
}

.sc-modal-tabs div {
    display: inline-block;
    margin-right: 20px;
    padding-right: 20px;
    border-right: 1px solid #6c757d;
    cursor: pointer;
}
.sc-modal-tabs .isActive{
    font-weight: 600;
}

.sc-modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #000;
    z-index: 1040;
    opacity: 0.5;
}

.sc-modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid #dee2e6;
}
.sc-modal-header h5 {
    margin: 3px 0;
    color: #212529;
}
.sc-modal-header button {
    font-weight: 400;
}

.sc-modal-body-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 90vh;
    overflow: auto;
    width: 500px;
    margin: 0;
    z-index: 1050;
    background: #fff;;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 5px;
}

.sc-modal-layout-children {
    padding: 16px;
}

.sc-modal-footer {
    text-align: right;
}
.sc-modal-footer::before {
    position: relative;
    content: '';
    left: -16px;
    display: block;
    width: calc(100% + 32px);
    margin: 20px 0 16px;
    border-top: 1px solid #dee2e6;
}

.sc-modal-footer button:first-child {
    padding: 7px 11px;
    margin-right: 8px;
}
