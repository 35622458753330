.sc-flex-grid-wrapper {
    overflow: hidden;
}

.sc-flex-grid {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
}

.sc-grid-empty-box {
    width: 200px;
    height: 0;
}
