.sc-tab {
  box-shadow: none !important;
}
.sc-tab .MuiTabs-root button {
  background: #fff;
  opacity: 1;
  text-transform: capitalize;
  color: #007bff;
  font-family: 'Open Sans', sans-serif;
}
.sc-tab button.Mui-selected {
  color: #495057;
  border: 1px solid #dee2e6;
  border-bottom: 1px solid #fff;
  cursor: auto;
  pointer-events: none;
  outline: none;
}
.sc-tab button.Mui-selected .MuiTab-wrapper{
  color: #495057;
}

.sc-tab .MuiTab-wrapper {
  color: #8ec154;
}

.sc-tab .MuiTabs-indicator {
  height: 1px;
  bottom: 0;
  background: #fff;
}

.sc-tab.MuiPaper-root {
  background-color: #fff;
}

.sc-tab .MuiTabs-flexContainer {
  background: #fff;
  border-bottom: 1px solid #dee2e6;
}

.sc-tab-view {
  display: none;
}

.sc-tab-view.isShowed {
  display: block;
}

.sc-tab-view .MuiPaper-root{
  box-shadow: none !important;
}