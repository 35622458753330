.sc-header {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 105px;
    padding: 32px 48px 32px 32px;
    background: #fff;
    z-index: 100;
}

.sc-header-logo {
    width: 150px;
    height: auto;
}

.sc-header-menu-icon {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.sc-header > ul {
    float: right;
    display: flex;
    list-style: none;
}

.sc-header-current-tab {
    margin: 9px 12px;
    font-weight: 600;
    border-bottom: 2px solid #8ec154;
}
.sc-header-tab {
    margin: 9px 12px;
    font-weight: 600;
    transition: 0.5s;
}
.sc-header-tab {
    display: block;
}
.sc-header-tab::after {
    content: '';
    display: block;
    height: 2px;
    opacity: 0;
    background: #8ec154;
    transition: 0.5s;
}
.sc-header-tab:hover::after {
    transition: 0.5s;
    opacity: 1;
}

.sc-header-email {
    margin: 9px 0 0 20px;
    cursor: pointer;
}
.sc-header-email img {
    width: 16px;
    height: 16px;
    margin-right: 6px;
}

.sc-header-menu-icon {
    display: none;
}
@media all and (max-width: 991px) {
    .sc-header > ul {
        display: block;
        position: fixed;
        top: 105px;
        left: 0;
        width: 100vw;
        max-height: 0;
        overflow: hidden;
        padding: 0;
        list-style: disc;
        background: #fff;
        transition: 0.5s;
    }
    .sc-header > ul p,
    .sc-header > ul a {
        margin: 0;
    }
    .sc-header > ul.isMenuOpen {
        max-height: 180px;
        padding: 0 0 20px 51px;
        transition: 0.5s;
    }
    .sc-header > ul li {
        margin-bottom: 12px;
    }

    .sc-header-menu-icon {
        display: block;
        float: right;
        margin-top: 12px;
    }

    .sc-header-current-tab {
        display: inline;
    }
    .sc-header-tab {
        display: inline-block;
    }
}
