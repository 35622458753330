.sc-date-range-picker {
  display: inline-block;
  position: relative;
}
.sc-date-range-picker.isOpen .rdrDateRangePickerWrapper {
  display: inline-block;
  position: absolute;
  top: 30px;
  left: 0;
  border: 1px solid #8ec154;
  border-radius: 4px;
  z-index: 100;
}
.sc-date-range-picker .rdrDateRangePickerWrapper {
  display: none;
  width: 560px;
  background: #fff;
}
.sc-date-range-picker .rdrDateRangePickerWrapper > div {
  float: left;
}
