.sc-progress-bar  {
    position: relative;
    height: 14px;
    background: #f4f8f9;
    border-radius: 7px;
}
.sc-progress-bar div {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    height: 14px;
    background: #8ec154;
    border-radius: 7px;
}