.sc-footer-top {
    padding: 32px 48px 32px 32px;
    background: #8ec154;
}

.sc-footer p,
.sc-footer a {
    display: inline-block;
    color: #fff;
    white-space: pre;
    margin: 0;
}

.sc-footer a {
    display: inline-block;
    border-bottom: 1px solid #fff;
    white-space: pre;
    font-weight: 600;
    color: #fff;
    transition: 0.5s;
}
.sc-footer a:hover {
    transition: 0.5s;
    border-bottom: 1px solid #000;
}

.sc-footer-bottom {
    padding: 32px 48px 32px 32px;
}
.sc-footer-bottom p,
.sc-footer-bottom a {
    color: #000;
    font-weight: 600;
}
.sc-footer-bottom a {
    border-bottom: 1px solid #000;
}
.sc-footer-bottom a:hover {
    border-bottom: 1px solid #8ec154;
    color: #8ec154;
    transition: 0.5s;
}
