.sc-radio-group.MuiFormGroup-root {
  flex-direction: row;
}

.sc-radio-group.MuiFormGroup-root .Mui-checked * {
  color: #8ec154;
}

.sc-radio-group .MuiButtonBase-root:hover,
.sc-radio-group .Mui-checked.MuiButtonBase-root:hover {
  background: rgba(139, 195, 74, 0.04);
}

.sc-radio-wrapper .MuiFormLabel-root,
.sc-radio-wrapper .MuiFormLabel-root.Mui-focused {
  color: #757575;
}
