.sc-card {
    display: flex;
    justify-content: space-around;
    min-height: 182px;
    margin-bottom: 24px;
    padding-top: 56px;
    background: #fff;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    transition: 0.6s;
}
.sc-card:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    text-decoration: none;
    transition: 0.6s;
}

.sc-card.isSimpleView {
    display: block;
    width: 200px;
    min-width: 200px;
    margin-right: 20px;
    padding-top: 35px;
}
.sc-card.isSimpleView:hover {
    border-color: #dee2e6;
}

.sc-card > * {
    width: calc(100% / 6);
}

.sc-card button {
    display: block;
    margin: 0 auto;
}

.sc-card.isSimpleView > * {
    max-width: 100%;
    width: 100%;
}

.sc-card.isSimpleView > div:first-child {
    width: 80px;
    margin: 0 auto;
}

.sc-card-icon {
    position: relative;
}
.sc-card-icon .sc-card-icon-background {
    position: relative;
    top: -8px;
    width: 80px;
    height: 80px;
    background: #8ec154;
    margin: 0 auto;
    border-radius: 50%;
    text-align: center;
}
.sc-card-icon i {
    color: #fff;
    position: absolute;
    display: block;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 30px;
}

.sc-card-values > * {
    text-align: center;
    font-size: 16px;
    padding: 0 10px;
}
.sc-card-values h6:first-child {
    font-weight: 600;
}

.sc-card-values p.isBold {
    font-weight: 600;
    margin: 10px 0 5px;
}

.sc-card.isSimpleView .sc-card-values:nth-child(2) h6 {
    margin: 18px 0 10px;
}

.sc-card button {
    height: 44px; 
}

.sc-card.isSimpleView button {
    width: auto;
    display: block;
    margin: 25px auto;
}

@media (max-width: 767px) {
    .sc-card {
        display: block;
    }
    .sc-card > * {
        max-width: 100%;
        width: 100%;
    }
    .sc-card > div:first-child {
        width: 80px;
        margin: 0 auto;
    }

    .sc-card button {
        width: auto;
        display: block;
        margin: 20px auto 30px;
    }
}
