.sc-textarea label {
    color: #212529;
}

.sc-textarea textarea {
    width: 100%;
    padding: 6px 12px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    color: #495057;
}

.sc-textarea textarea + label {
    font-size: 12px;
    color: #6c757d;
}