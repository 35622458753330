.sc-box-layout .isButton {
    position: relative;
}

.sc-box-layout .isButton .sc-box-layout-title {
    margin-top: 24px;
}
.sc-box-layout .isButton .sc-box-layout-title.isShadowLineHeader {
    margin-top: 48px;
}
.sc-box-layout .isButton > button {
    position: absolute;
}

.sc-box-layout-title {
    text-align: center;
    border-bottom: none;
    margin-bottom: 0;
}
.sc-box-layout-title.isUnderlinedHeader {
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 16px;
}
.sc-box-layout-title.isShadowLineHeader {
    position: relative;
}
.sc-box-layout-title.isShadowLineHeader::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 18px;
    margin: 0 auto;
    height: 3px;
    width: 80%;
    background: linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(130,216,80,1) 25%, rgba(130,216,80,1) 75%, rgba(0,212,255,0) 100%);
}

.sc-box-layout-title.isShadowLineHeader h2 {
    position: relative;
    z-index: 2;
}
.sc-box-layout-title.isShadowLineHeader h2::before {
    content: '';
    position: absolute;
    top: -4px;
    left: -42px;
    height: 30px;
    width: calc(100% + 60px);
    background: #fff;
    z-index: -1;
}


.sc-box-layout-title i {
    position: relative;
    display: inline-block;
    margin-right: 8px;
    color: #8ec154;
    font-size: 20px;
    z-index: 3;
}
.sc-box-layout-title h2 {
    display: inline-block;
    margin: 8px 0;
    padding: 0;
    font-size: 20px;
}
.sc-box-layout-title.isUnderlinedHeader h2 {
    padding-bottom: 8px;
}

.sc-box-layout-description {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.sc-box-layout-description h2 {
    font-size: 16px;
    font-weight: 600;
}

.sc-box-layout-description h2 span {
    color: #8ec154;
}
.sc-box-layout-description h2 span span {
    color: #212529;
}

.sc-box-layout-description p {
    font-size: 14px;
    line-height: 28px;
    color: #212529;
}

.sc-box-layout-description button {
    height: 44px;
    margin-left: 20px;
    white-space: nowrap;
}

.sc-box-layout-description + .sc-progress-bar {
    margin: 18px;
}
