.sc-switch-wrapper {
  width: 100%;
  margin-bottom: 20px;
}
.sc-switch.MuiSwitch-root {
  width: 46px;
  height: 26.5px;
  margin-top: 26px;
  padding: 0;
}
.sc-switch .MuiSwitch-track {
  border-radius: 24px;
}
.sc-switch .MuiSwitch-switchBase {
  top: -6px;
  left: -6px;
}
.sc-switch .Mui-checked + span.MuiSwitch-track {
  opacity: 1;
  background-color: #8ec154;
}
.sc-switch .Mui-checked span.MuiSwitch-thumb {
  color: #fff;
}
.sc-switch .Mui-checked span.MuiTouchRipple-root > span {
  color: #8ec154;
}

label.sc-switch-label,
label.sc-switch-label.Mui-focused {
  color: #757575;
}
.sc-switch-label.MuiInputLabel-outlined.MuiInputLabel-animated {
  transform: none;
}
