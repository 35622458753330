button.sc-button {
    position: relative;
    padding: 8px 16px;
    outline: none !important;
    border: 2px solid #8ec154;
    border-radius: 4px;
    transition: 0.6s;
    color: #fff;
    background: #8ec154;
}
button.sc-button.isLoading:hover,
button.sc-button.isDisabled {
    transition: color 0s;
    pointer-events: none;
    border: 2px solid #b4e896;
    background: #b4e896;
    cursor: auto;
}
.sc-button:hover {
    color: #8ec154;
    background: #fff;
    transition: 0.6s;
}
.sc-button .sc-loader {
    position: absolute;
    left: 0;
    right: 0;
    width: 20px;
    height: 20px;
    margin: 1px auto;
}
.sc-button .sc-loader::after {
    width: 20px;
    height: 20px;
    border-top: 0.2em solid #fff;
    border-right: 0.2em solid #fff;
    border-bottom: 0.2em solid #fff;
    border-left: 0.2em solid #28a743;
}
.sc-button .sc-loader + span {
    color: #b4e896;
}
.sc-button.isDisabled:hover {
    color: #fff;
    background: #b4e896;
}

.sc-button.isWhite {
    color: #8ec154;
    background: #fff;
}
.sc-button.isWhite.isDisabled {
    color: #b4e896;
    background: #fff;
}
.sc-button.isWhite:hover {
    color: #fff;
    background: #8ec154;
}
.sc-button.isDark {
    padding: 10px 16px;
    border: none;
    background: #6c757d;
}
.sc-button.isDark:hover {
    color: #fff;
    background: #5a6268;
}
.sc-button.isDanger {
    padding: 8px 14px;
    background: #dc3545;
    border: 2px solid #dc3545;
}
.sc-button.isDanger:hover {
    color: #dc3545;
    background: #fff;
}

button.sc-button-red {
    color: #fff;
    background: #dc3545;
    border: 2px solid #dc3545;
    transition: 0.6s;
}
button.sc-button-red:hover {
    color: #dc3545;
    background: #fff;
    border: 2px solid #dc3545;
}

button.sc-button-naked {
    color: #8ec154;
    padding: 4px 10px;
    border: none;
    background: none;
    transition: 0.6s;
}
button.sc-button-naked.isDisabled {
    background: none;
    border: none;
}
button.sc-button-naked.isDisabled:hover {
    color: #8ec154;
    background: none;
}
button.sc-button-naked:hover {
    color: #fff;
    background: #8ec154;
    transition: 0.6s;
}

button.btn-action {
    background: #8ec154;
    border-color: #8ec154;
}

.sc-button i {
    margin-right: 5px;
}

.sc-button + .sc-reset-button {
    display: inline-block;
    padding: 0 7px 0 7px;
    cursor: pointer;
}
.sc-button + .sc-reset-button i {
    color: #dc3545;
    font-size: 10px;
}
