.sc-table-wrapper.hasMaxHeight {
    overflow: auto;
}

.sc-table-wrapper > button {
    float: right;
}

.sc-table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    color: #212121;
}

.sc-table-wrapper tr.hasHiddenValue.isExpanded,
.sc-table .main-tr:hover td {
    background: #ececec;
}

.sc-table-display-data,
.sc-table th {
    padding: 12px;
}

.sc-table td {
    position: relative;
    word-break: break-all;
}

.sc-table td i {
    color: #8ec154;
    margin-right: 8px;
}

.sc-table td.custom-td {
    padding: 2px 6px;
}
.sc-table td.custom-td button {
    padding: 4px 10px;
}
.sc-table td.custom-td button:not(:first-child) {
    margin-left: 4px;
}
.sc-table td.custom-td > span {
    font-size: 12px;
    opacity: 0.7;
}

.sc-table-wrapper tr.hasHiddenValue:hover {
    cursor: pointer;
}
.sc-table .main-tr.isCreationState:hover td {
    cursor: auto;
}
.sc-table-wrapper tr.hasHiddenValue.isExpanded + tr,
.sc-table-wrapper tr.hasHiddenValue.isExpanded + tr + .sc-table-second-hidden-tr {
    background: #f4f8f9;
}
.sc-table-wrapper tr.isExpanded + tr .sc-table-hidden,
.sc-table-wrapper tr.isExpanded + .sc-table-second-hidden-tr .sc-table-second-hidden,
.sc-table-wrapper tr.isExpanded + tr + .sc-table-second-hidden-tr .sc-table-second-hidden {
    max-height: 400px;
    transition: max-height 0.4s;
}

.sc-table-second-hidden-tr td:not(:first-child) {
    display: none;
}

.sc-table-hidden .sc-table-tooltip {
    display: none;
    position: absolute;
    top: 10px;
    max-height: 160px;
    overflow: auto;
    padding: 4px 8px;
    border: 1px solid #8ec154;
    border-radius: 4px;
    background: #fff;
    z-index: 10;
    word-break: initial;
}
.sc-table-hidden:hover {
    overflow: visible;
}
.sc-table-hidden:hover .sc-table-tooltip{
    display: block;
}

.sc-table-hidden,
.sc-table-second-hidden {
    max-height: 0;
    overflow: hidden;
    word-break: break-all;
    transition: max-height 0.4s;
}
.sc-table-hidden > *,
.sc-table-second-hidden > * {
    margin: 12px;
}
.sc-table-hidden button {
    margin-right: 0;
}

.sc-table-hidden p,
.sc-table-second-hidden p {
    padding: 0;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
}

.sc-table-hidden-tbody {
    display: grid;
    max-height: 34px;
    overflow: hidden;
}
.sc-table-hidden-tbody.isExpanded {
    display: contents;
}

.sc-table-hidden-tbody .custom-td button {
    display: none;
}

.sc-table-empty-list {
    background: #f4f8f9;
    border-radius: 2px;
    text-align: center;
    padding: 12px;
    margin: 9px 0;
}
.sc-table-empty-list i {
    color: #8ec154;
    margin-right: 8px;
}
.sc-table-empty-list h2 {
    display: inline-block;
    font-size: 16px;
    margin-bottom: 0;
}
