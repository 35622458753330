@import url('https://fonts.googleapis.com/css?family=Open+Sans');

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #efefef;
    padding-top: 105px;
}

a {
    color: #212529;
}
a:hover {
    text-decoration: none;
    color: #212529;
}

.sc-main-layout {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 106px);
}

.sc-main-layout-toolbar {
    height: 60px;
    padding: 0 37px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.sc-main-layout-toolbar.empty {
    height: 30px;
}

.sc-main-layout-body {
    flex: 1;
    padding: 0 30px 30px 30px;
}

.sc-box {
    padding: 24px;
    background: #fff;
    border: 1px solid #dee2e6;
    border-radius: 6px;
}
.sc-box h1,
.sc-box h2,
.sc-box h3,
.sc-box h4,
.sc-box h5,
.sc-box h6,
.sc-box p {
    font-family: 'Open Sans', sans-serif;
}

.sc-indent-top {
    margin: 0 7px;
    border-bottom: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.sc-indent-middle {
    margin: 0 7px;
    border-top: none;
    border-bottom: none;
    border-radius: 0px;
}

.sc-indent-bottom {
    margin: 0 7px;
    border-top: none;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.sc-box-overflow {
    position: relative;
    left: -8px;
    width: calc(100% + 16px);
}

.sc-no-padding {
    padding: 0;
}

.sc-loader {
    margin: 40px 0;
    height: 120px;
    overflow: hidden;
    position: relative;
}

.sc-loader::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 50%;
    height: 7em;
    width: 7em;
    border-top: 1.1em solid rgba(40, 167, 67, 0.2);
    border-right: 1.1em solid rgba(40, 167, 67, 0.2);
    border-bottom: 1.1em solid rgba(40, 167, 67, 0.2);
    border-left: 1.1em solid #28a743;
    transform: translateZ(0);
    animation: rotate360 1.1s infinite linear;
}

.sc-no-data-message {
    width: 100%;
    margin: 20px 20px 40px;
    font-size: 1.2em;
    text-align: center;
}

@keyframes rotate360 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
