.sc-select-wrapper {
  width: 100%;
}
.sc-select-wrapper.MuiFormControl-root {
  margin-bottom: 20px;
}
.sc-select-wrapper .MuiInputLabel-outlined.MuiInputLabel-animated {
  transform: none;
}
.sc-select-wrapper .MuiOutlinedInput-input {
  padding: 9.5px 14px;
}
.sc-select-wrapper label,
.sc-select-wrapper label.Mui-focused {
  color: #757575;
}
.sc-select-wrapper.isError label {
  color: #f44336;
}
.sc-select-wrapper label + .MuiInputBase-formControl {
  margin-top: 26px;
}
.sc-select-wrapper legend {
  max-width: 0;
}

.sc-multiple-select {
  min-height: 38px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
}
.sc-multiple-select:hover {
  border: 1px solid #212121;
}

.sc-multiple-select.Mui-error,
.sc-multiple-select.Mui-error:hover {
  border: 1px solid #f44336;
}
.sc-multiple-select.Mui-focused,
.sc-multiple-select.Mui-focused:hover {
  border: 2px solid #28a745;
}
.sc-multiple-select.Mui-focused > div {
  min-height: 30px;
  padding: 2px 40px 2px 2px;
}
.sc-multiple-select.Mui-focused > svg {
  right: 6px;
}
.sc-select.Mui-focused fieldset.MuiOutlinedInput-notchedOutline,
.sc-select.Mui-focused:hover fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #28a745;
}
.sc-multiple-select.Mui-focused.Mui-error,
.sc-multiple-select.Mui-focused.Mui-error:hover,
.sc-select.Mui-focused.Mui-error fieldset.MuiOutlinedInput-notchedOutline,
.sc-select.Mui-focused.Mui-error:hover
  fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #f44336;
}

.sc-multiple-select > div {
  padding: 3px 41px 3px 3px;
}

.sc-multiple-select .MuiSelect-selectMenu {
  overflow: unset;
  white-space: normal;
}

.sc-multiple-select .MuiChip-root {
  height: 26px;
  margin: 2px;
}

.sc-multiple-select.MuiInput-underline::before,
.sc-multiple-select.MuiInput-underline::after {
  border: none !important;
}
