.announcement{
    color: red;
    border: 1px solid black;
    position: absolute;
    width:40%;
}

.anncouncement-container{
    text-align: center;
    margin-left: 20%;
    display: inline-block;
}




@media only screen and (max-width: 1750px) 
{
.announcement{
    color: red;
    border: 1px solid black;
    position: absolute;
    width:50%;
}

.anncouncement-container{
    text-align: center;
    margin-left: 10%;
    display: inline-block;


}
};

